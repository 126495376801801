import template from './dashboard.html';

class DashboardViewModel
{
	constructor (page)
	{
		this.page = page;
	}

	btn_key_indicators_settings_click ()
	{
		console.log('key indicators settings button clicked');
	}

	btn_share_graph_click ()
	{
		console.log('share graph button clicked');
	}

	btn_print_doc_click ()
	{
		console.log('print doc button clicked');
	}

	btn_download_pdf_click ()
	{
		console.log('download PDF button clicked');
	}

	btn_graph_settings_click ()
	{
		console.log('graph settings button clicked');
	}
	
	btn_expand_graph_click ()
	{
		console.log('expand graph button clicked');
	}
}

class DashboardPageClass
{
	constructor (bindings, element)
	{
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new DashboardViewModel(this);
		this.name = 'DashboardPageClass';
	}

	init ()
	{
		console.log('dashboard init running');
		document.title = 'CRM Dashboard';
	}

	updateData ()
	{
		console.log('dashboard updateData running');
	}
}

export default {
	route: '[/]ui/crm/dashboard',
	page_class: DashboardPageClass,
	template: template
};
