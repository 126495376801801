import template from './category-setup.html';

class CategorySetupVM
{
	constructor (page)
	{
		this.page = page;
		this.message_categories = ko.observable();
	}

	async btn_add_category()
	{
		await Grape.dialog.open('message-category', {data: null});
		this.page.updateData();
	}

	async btn_edit_category(data)
	{
		await Grape.dialog.open('message-category', {data: data});
		this.page.updateData();
	}

	async btn_remove_category(data)
	{
		let response = await Grape.alerts.confirm({ type: 'warning', message: 'Are you sure you want to delete this category?'});
		if (response)
		{
			//delete the category and either all queries linked to it, or say that you cannot because there are queries linked to it.
		}
	}
}

class CategorySetupPage
{
	constructor (bindings, element, page)
	{
		this.viewModel = new CategorySetupVM(this);
		this.bindings = bindings;
		document.title = 'Messages setup: Category Setup';
	}

	async init ()
	{
		await this.updateData();
	}

	async updateData()
	{
		let res_types = await Grape.cache.fetch('MessageCategory');
		if (res_types.status !== 'ERROR')
			this.viewModel.message_categories(res_types);
	}

	teardown ()
	{
	}
}

export default {
	route: '[/ui/crm/setup/]category-setup',
	page_class: CategorySetupPage,
	template: template
}
