import template from './setup-page-content.html';

class SetupPageContentVM
{
	constructor (page)
	{
		this.page = page;
		this.current_route = ko.observable();
	}

	load_page (route)
	{
		window.localStorage.setItem("last_setup_page", route);
		const element = this.page.element.querySelector("#setup_page_content");
		this.current_route(route);
		const page = Grape.pages.find_page_by_id(route);
		if (!page)
			throw new Error(`Page with id "${route}" not found`);
		Grape.pages.load_page(page, element, {}, {}, () => {});
	}

	btn_back_click()
	{
		Grape.navigate('/ui/crm/setup-page')
	}
}

class SetupPageContent
{
	constructor (bindings, element)
	{
		this.viewModel = new SetupPageContentVM(this);
		this.bindings = bindings;
		this.element = element;
	}

	init ()
	{
		document.title = 'CRM Setup';
		if (this.bindings.route != '' && this.bindings.route != undefined)
			this.viewModel.load_page(this.bindings.route);
		else if (window.localStorage.getItem("last_setup_page"))
			this.viewModel.load_page(window.localStorage.getItem("last_setup_page"));
		else
			Grape.navigate('/ui/crm/setup-page')
	}
}

export default {
	route: '[/ui/crm/setup/]page/content',
	page_class: SetupPageContent,
	template: template
}
