
// COMPONENTS
import navbar from './navbar/navbar.js';
import table_fields_view from './custom-fields/table-fields-view.js';
import checkbox_dropdown from './checkbox-dropdown/checkbox-dropdown.js';

// Dashboard graphs components
import crm_graph_filter from './dashboard-graphs/graph-filter/graph-filter.js';
import crm_age_analysis_graph_panel from './dashboard-graphs/graph-panels/age-analysis-panel.js';
import crm_volumes_graph_panel from './dashboard-graphs/graph-panels/volumes-panel.js';

export default [
	navbar,
	table_fields_view,
	crm_graph_filter,
	crm_age_analysis_graph_panel,
	crm_volumes_graph_panel,
	checkbox_dropdown
];
