class NavbarHelper
{
	constructor ()
	{
		if (window)
			window.NavbarHelper = this;

		this.hideTimeout;
		this.scrollVelocityThreshold = 3; // TODO make configurable per user
		this.hideDelay = 2000; // TODO make configurable per user
		this.offsetHeight = document.querySelector('.ps-navbar').offsetHeight;

		this.product_name = ko.observable();
		this.service_name = ko.observable();
		this.site_name = ko.observable();

		this.init();
	}

	init ()
	{
		this.product_name(window.Grape.config.public_settings?.product_name);
		this.service_name(window.Grape.config.public_settings?.service_name);
		this.site_name(window.Grape.config.public_settings?.site_name);

		this.initListeners();
	}

	hideNav ()
	{
		let navbar = document.querySelector('.ps-navbar');
		let dropdownMenu = document.querySelectorAll('.ps-nav-dropdown');
		navbar.style.transform = 'translateY(-100%)'; // TODO rather make sticky
		this.addClassToElements(dropdownMenu, 'ps-dropdown-hide');
		navbar.style["box-shadow"]="";
	}

	showNav ()
	{
		let navbar = document.querySelector('.ps-navbar');
		let dropdownMenu = document.querySelectorAll('.ps-nav-dropdown');
		navbar.style.transform = 'translateY(0)'; // TODO rather make sticky
		this.removeClassFromElements(dropdownMenu, 'ps-dropdown-hide');
		if (window.scrollY >= this.offsetHeight) {
			navbar.style.position = 'fixed';
			navbar.style["box-shadow"]="var(--fg-c1) 0 0 10px 1px";
		} else {
			navbar.style.position = 'absolute';
			navbar.style["box-shadow"]="";
		}
	}

	async initListeners ()
	{
		let scrollBefore = 0;
		let timeBefore = 0;
		let navbar = document.querySelector('.ps-navbar');
		let navItems = document.querySelectorAll('.ps-nav-links > li, .ps-nav-links-right > li, .ps-nav-links .ps-nav-links-right > li');

		navItems.forEach(item => {
			item.addEventListener('click', (event) => {
				event.stopPropagation();

				let open = item.classList.contains('open');

				navItems.forEach(navItem => {
					if (navItem !== item)
						navItem.classList.remove('open');
				});

				if (!open)
					item.classList.add('open');
				else
					item.classList.remove('open');
			});
		});

		document.addEventListener('click', () => {
			navItems.forEach(item => {
				item.classList.remove('open');
			});
		});

		navbar.addEventListener('mouseenter', ()=>{
			clearTimeout(this.hideTimeout);
			this.showNav();
		});

		navbar.addEventListener('mouseleave', ()=>{
			clearTimeout(this.hideTimeout);
			if (window.scrollY !== 0)
				this.hideTimeout = setTimeout(()=>{if (window.scrollY>=this.offsetHeight)this.hideNav()}, this.hideDelay);
		});

		document.documentElement.addEventListener('mouseleave', ()=>{
			this.showNav();
			clearTimeout(this.hideTimeout);
			if (window.scrollY !== 0)
				this.hideTimeout = setTimeout(()=>{if (window.scrollY>=this.offsetHeight)this.hideNav()}, this.hideDelay);
		});

		window.addEventListener('scroll', () => {
			let scrollPosition = window.scrollY;

			if (scrollPosition < navbar.offsetHeight)
			{
				clearTimeout(this.hideTimeout);
				this.showNav();
				return;
			}

			let timeDelta = Date.now()-timeBefore;
			timeBefore = Date.now();
			let scrollDelta = scrollBefore - scrollPosition;
			scrollBefore = scrollPosition;
			let scrollVelocity = scrollDelta/timeDelta;

			if (scrollVelocity>0) // scroll up
			{
				if (scrollVelocity <= this.scrollVelocityThreshold)
					return;

				if (navbar.style.transform == 'translateY(-100%)')
				{
					this.showNav();

					if (scrollPosition !== 0)
					{
						clearTimeout(this.hideTimeout);
						this.hideTimeout = setTimeout(()=>{if (window.scrollY>=this.offsetHeight)this.hideNav();}, this.hideDelay);
					}
				}
			}
			else // scroll down
			{
				clearTimeout(this.hideTimeout);
				scrollBefore = scrollPosition;
				if (scrollPosition != 0)
				{
					this.hideNav();
					this.checkMobilePosition();
				}
				else
					this.showNav();
			}
		});
	}

	checkMobilePosition ()
	{
		const mediaQuery = window.matchMedia('(max-width: 970px)');

		// Check if the media query matches
		if (mediaQuery.matches)
		{
			const psMenuBtn = document.getElementById('ps-menu-btn');
			psMenuBtn.checked = false;
		}
	}

	removeClassFromElements (elements, className)
	{
		elements.forEach((element) => {
			element.classList.remove(className);
		});
	}

	addClassToElements (elements, className)
	{
		elements.forEach((element) => {
			element.classList.add(className);
		});
	}

	async onLoad()
	{
		// TODO
	}

	async onSessionChange(session)
	{
		// TODO
	}
}

export default NavbarHelper;
