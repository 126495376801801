
import template from './system-init.html';

/**
 *  Page view model
 */
class SysInitViewModel
{
	constructor(page)
	{
		this.page = page;
		this.element = page.element;
		this.templates = ko.observableArray();
		this.setupOption = ko.observable('template');
		this.selectedTemplate = ko.observable('FabulousFruits');
		this.state = ko.observable(null);
		this.schedule_id = ko.observable();
		this.log = ko.observable();
		this.username = ko.observable('alice');
		this.password = ko.observable('alice123');
		this.email = ko.observable('alice@platsoft.net');
		this.template_password = ko.observable('');
		this.error_message = ko.observable('');
	}

	async update()
	{
		let r = window.Grape?.config?.public_settings?.system_setup || null;
		if (r === null)
			this.state('done');
		else if (r === 'true')
		{
			let result = await Grape.fetches.getJSON(`/crm/sysinit`);
			this.templates(result);
			this.state('ready');
		}
		else if (r === 'error')
		{
			if (this.error_message() == '')
				this.error_message('Unknown system setup error occurred. Please contact support.');
			this.state('error');
		}
		else if (r === 'running')
			this.state('running');
	}

	async checkIfDone()
	{
		let result;
		try {
			result = await Grape.fetches.getJSON(`/api/session/info`);
		} catch (err) {
			// Network error
			this.timer = setTimeout(() => this.checkIfDone(), 1000);
			return;
		}

		if (result.public_settings.hasOwnProperty('system_setup'))
		{
			if (result.public_settings.system_setup == 'error')
			{
				if (this.error_message() == '')
					this.error_message('Unknown system setup error occurred. Please contact support.');
				this.state('error');
			}
			else if (result.public_settings.system_setup == 'running')
				this.timer = setTimeout(() => this.checkIfDone(), 1000);
			else if (result.public_settings.system_setup == 'true')
				this.state('ready');
			else
			{
				this.error_message('Unknown value for "system_setup" setting.');
				this.state('error');
			}
		}
		else
		{
			this.state('done');
			window.location.reload();
		}
	}

	async btnNext_click()
	{
		if (!this.setupOption())
			return;

		if (this.setupOption() == 'template')
		{
			if (!this.selectedTemplate())
			{
				Grape.alerts.alert({type: 'error', message: 'Select a template first'});
				return;
			}
			const template = this.templates().find(x => x.name == this.selectedTemplate());
			if (template.protected === true)
			{
				const response = await Grape.alerts.prompt({message: 'Please enter the template password', title: 'Protected template'});
				if (!response)
					return;
				this.template_password(response.prompt);
			}
			this.state('credentials');
		}
		else if (this.setupOption() == 'blank' || this.setupOption() == 'wizard')
		{
			this.state('credentials');
		}
	}

	async btnNextCreds_click()
	{
		this.state('running');
		const input = {
				user: {
					username: this.username(),
					password: this.password(),
					email: this.email()
				},
		};

		if (this.setupOption() == 'template')
		{
			input.template_password = this.template_password();
			input.template = this.selectedTemplate();
		}
		else if (this.setupOption() == 'blank' || this.setupOption() == 'wizard')
		{
			input.user = {
				username: this.username(),
				password: this.password(),
				email: this.email()
			};
		}
		else
		{
			throw new Error('NotImplemented');
		}

		let success;
		try {
			const result = await Grape.fetches.postJSON(`/crm/sysinit`, input);
			console.log('result=',result);
			if (result.status == 'OK')
			{
				success = true;
			}
			else
			{
				success = false;
				if (result.hasOwnProperty('c') && result.c === false)
					this.error_message('This template is protected. The template password you provided is not correct.');
				else if (result.message)
					this.error_message(result.message);
				else
					this.error_message(`Something went wrong. result=${JSON.stringify(result)}`);

				this.state('error');
			}
		} catch (err) {
			console.error(err);
			success = false;
			this.error_message(`err.message`);
			this.state('error');
		}
		if (success === true)
			this.timer = setTimeout(() => this.checkIfDone(), 1500);

	}
}

/**
 * Page class
 */
class SysInitPageClass
{
	constructor(bindings, element)
	{
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new SysInitViewModel(this);
		this.name = 'SysInitPageClass';
	}

	async init()
	{
		await this.viewModel.update();
	}

	async updateData() {}

	async teardown() {}
}

export default {
	route: '[/]ui/crm/system-init',
	page_class: SysInitPageClass,
	template: template
};
