import template from './graph-filter.html';

class CRMGraphFilterComponentViewModel
{
	constructor (params)
	{
		this.params = params;

		console.log('params for graph filter: ', params);
		this.include_dates = ko.observable(params.include_dates);

		this.date_start_input = ko.observable(params.date_start ? params.date_start() : '');
		this.date_end_input = ko.observable(params.date_end ? params.date_end() : '');
		this.time_span_interval_input = ko.observable(params.time_span_interval());
		this.time_span_input = ko.observable(params.time_span());

		// Date Observables
		this.date_start = ko_helper.safe_observable(params.date_start);
		this.date_end = ko_helper.safe_observable(params.date_end);

		// Interval Observables
		this.time_span_interval = ko_helper.safe_observable(params);
		this.time_span = ko_helper.safe_observable();

		this.date_start_input.subscribe((newValue) => {
			console.log('new value for start date: ', newValue);

			if (this.is_valid_date(newValue))
				this.date_start(newValue);
		});

		this.date_end_input.subscribe((newValue) => {
			console.log('new value for end date: ', newValue);

			if (this.is_valid_date(newValue))
				this.date_end(newValue);
		});

		this.time_span_interval_input.subscribe((newValue) => {
			console.log('new value for time span interval: ', newValue);

			if (newValue)
				this.time_span_interval(newValue);
		});

		this.time_span_input.subscribe((newValue) => {
			console.log('new value for time span: ', newValue);

			if (newValue)
				this.time_span(newValue);
		});
	}

	is_valid_date (date_string)
	{
		return moment(date_string, 'YYYY-MM-DD', true).isValid();
	}
}

export default {
	name: 'crm-graph-filter',
	viewModel: CRMGraphFilterComponentViewModel,
	module_type: 'ko',
	template: template
}