import template from './state-settings.html';

class StateSettingsVM
{
	constructor (page)
	{
		this.page = page;
		this.active_states = ko.observableArray([{
			active: 'OPEN/', state: 'New', description: 'New incoming message, not attended to yet'
		},
		{
			active: 'OPEN/', state: 'In Progess', description: 'Incoming message, busy being attended to'
		},
		{
			active: 'OPEN/', state: 'Waiting Feedback', description: 'Done, awaiting feedback'
		},
		{
			active: 'OPEN/', state: 'Escalated', description: 'Escalated to further investigate'
		}
	]);
	}
}

class StateSettingsPage
{
	constructor (bindings, element, page)
	{
		this.viewModel = new StateSettingsVM(this);
		this.bindings = bindings;
		document.title = 'Messages setup: States Settings';
	}

	async init ()
	{
	}

	teardown ()
	{
	}
}

export default {
	route: '[/ui/crm/setup/]state-settings',
	page_class: StateSettingsPage,
	template: template
}
