
import template from './setup.html';

/**
 *  Page view model
 */
class SetupPageViewModel
{
	constructor(page)
	{
		this.page = page;
		this.element = page.element;
		this.lookupValueListOptions = ko.observable({filter:[{field:'schema', operator:'=', value:''}]});
		this.selectedFieldsTable = ko.observable();
		this.selectedFieldsTable.subscribe((newValue)=>{
			this.lookupValueListOptions({filter:[
				{field:'schema', operator:'=', value:newValue.schema},
				{field:'table', operator:'=', value:newValue.table}
			]});
		});
		this.selectedLookupValue = ko.observable();

	}

	btn_route_click(route)
	{
		Grape.navigate('/ui/crm/setup/page/content', { route: route });
	}
}

/**
 * Page class
 */
class CRMSetupPageClass
{
	constructor(bindings, element)
	{
		document.title = 'Setup';
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new SetupPageViewModel(this);
		this.name = 'CRMSetupPageClass';
	}

	init()
	{}
}

export default {
	route: '[/]ui/crm/setup-page',
	page_class: CRMSetupPageClass,
	template: template
};
