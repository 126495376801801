import template from './working-hours.html';

class WorkingHoursVM
{
	constructor (page)
	{
		this.page = page;
		this.show_business_hours = ko.observable(false);
		this.show_holidays = ko.observable(false);
		this.select_days = ko.observable(false);
		this.full_day = ko.observable(true);
		this.time_zones = ko.observableArray([{text: '(GMT+02:00) Pretoria', value: '+2'}, {text: '(GMT+01:00) United Kingdom', value: '+1'}, {text: '(GMT-04:00) Washington, DC', value: '-4'}, ]);
		this.time_off = ko.observable(true);
		this.from_select = ko.observableArray(['am', 'pm']);
		this.weekdays = ko.observableArray([{ day: 'Monday'}, {day:'Tuesday'}, {day:'Wednesday'}, {day:'Thursday'}, {day:'Friday'}, {day:'Saturday'}, {day:'Sunday'}]);

		this.full_day.subscribe((val) => {
			this.select_days(!val);
		});

		this.select_days.subscribe((val) => {
			this.full_day(!val);
		})
	}

	async load()
	{
	}

	btn_toggle_business_hours()
	{
		this.show_business_hours(!this.show_business_hours());
	}

	btn_toggle_holidays()
	{
		this.show_holidays(!this.show_holidays());
	}
}

class WorkingHoursPage
{
	constructor (bindings, element, page)
	{
		this.viewModel = new WorkingHoursVM(this);
		this.bindings = bindings;
		document.title = 'Working Hours Setup';
	}

	async init ()
	{
		this.viewModel.load();
	}

	teardown ()
	{
	}
}

export default {
	route: '[/ui/crm/setup/working-hours',
	page_class: WorkingHoursPage,
	template: template
}
