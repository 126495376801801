import template from './message-category.html';

class MessageCategoryVM
{
	constructor(dialog)
	{
		this.dialog = dialog;
		this.edit = ko.observable(true);
		this.name = ko.observable();
		this.description = ko.observable();
	}

	close()
	{
		this.dialog.close();
	}

	async save()
	{
		
	}
}

class MessageCategoryDialog
{
	constructor(bindings, element)
	{
		this.bindings = bindings || {};
		this.viewModel = new MessageCategoryVM(this);
		this.name = 'message-category';
	}

	init()
	{
		console.log('bindings', this.bindings);
		if (this.bindings.data)
		{
			this.viewModel.edit(true)
			this.viewModel.name(this.bindings.data.name);
			this.viewModel.description(this.bindings.data.description);
		}
		else
			this.viewModel.edit(false);
	}
}

export default {
	name: 'message-category',
	dialog_class: MessageCategoryDialog,
	provider: 'ps',
	template: template
}