import template from './navbar.html';
import NavbarHelper from '@platsoft/ui-ps/src/navbar/NavbarHelper.js';

class NavbarViewModel extends NavbarHelper
{
	constructor (Grape)
	{
		super(Grape);
	}

	init ()
	{
		super.init();
		this.app_logo = ko.observable(Grape.config.public_settings?.app_logo || null);
		this.title_visibility = ko.observable(true);
		this.current_page_route = ko.observable();
		this.active_nav = ko.observable(null);
		this.active_side_nav = ko.observable(null);
		this.active_side_nav_main = ko.observable(null);
		this.side_nav_visible = ko.observable(true);

		this.side_nav_items = ko.observableArray([
			{
				page_route: '/ui/crm/dashboard',
				title: 'Dashboard',
				icon: 'fa-light fa-grid-horizontal',
				sub_pages: [],
				sub_pages_visible: ko.observable(false),
				data_visible_roles: 'all'
			},
			{
				page_route: '/ui/engagements/queries/',
				title: 'Queries',
				icon: 'fa-light fa-layer-group',
				sub_pages: [],
				sub_pages_visible: ko.observable(false),
				data_visible_roles: 'all'
			},
			{
				page_route: '/ui/engagements/queries/my-queries',
				title: 'My Queries',
				icon: 'fa-light fa-clipboard-question',
				sub_pages: [
					//{ page_route: '/stock/stock_levels', title: 'Stock Levels', icon: '' },
					//{ page_route: '/stock/stock_levels/summary', title: 'Stock Levels Summary', icon: '' },
				],
				sub_pages_visible: ko.observable(false),
				data_visible_roles: 'all'
			},
			{
				page_route: 'ui/crm/contacts/search',
				title: 'Contacts',
				icon: 'fa-light fa-address-book',
				sub_pages: [],
				sub_pages_visible: ko.observable(false),
				data_visible_roles: 'all',
			},
			{
				page_route: 'ui/crm/setup-page',
				title: 'Setup',
				icon: 'fa-light fa-sliders',
				sub_pages: [],
				sub_pages_visible: ko.observable(false),
				data_visible_roles: 'all'
			}
		]);

		window.Grape.update_visible_roles();
	}

	btn_toggle_visibility ()
	{
		let sidebar = document.getElementById('resizable-sidebar');
		let ul = document.getElementById('resizable-ul');

		if (this.side_nav_visible())
		{
			this.side_nav_items().forEach(item => {
				if (item === this.active_side_nav_main())
					item.sub_pages_visible(false);
			});

			sidebar.style.minWidth = '4em';
			ul.style.width = '4em';
			ul.style.marginLeft = '-1em'
		}
		else
		{
			this.side_nav_items().forEach(item => {
				if (item === this.active_side_nav_main())
					item.sub_pages_visible(true);
			});

			sidebar.style.minWidth = '17em';
			ul.style.width = '17em';
			ul.style.marginLeft = '0'
		}

		this.side_nav_visible(!this.side_nav_visible());
	}

	open_side_nav_main_item(side_nav_main_item)
	{
		let sidebar = document.getElementById('resizable-sidebar');
		
		if (sidebar.style.minWidth === '4em' && side_nav_main_item.page_route === null)
			this.btn_toggle_visibility();

		this.active_side_nav_main(side_nav_main_item);
		this.side_nav_items().forEach(item => {
			if (item === side_nav_main_item)
				item.sub_pages_visible(true);
			else
				item.sub_pages_visible(false);
		});

		if (side_nav_main_item.page_route != null)
		{
			this.navigate(side_nav_main_item.page_route);
			this.active_nav(null);
			this.active_side_nav(null);
		}
	}

	set_side_nav_page(side_nav_item)
	{
		this.active_side_nav(side_nav_item);

		if (side_nav_item.page_route != null)
		{
			this.navigate(side_nav_item.page_route);
			this.active_nav(null);
		}
	}

	set_nav_page(nav_item, page_route)
	{
		this.navigate(page_route);
		this.active_nav(nav_item);
		this.active_side_nav(null);

		this.side_nav_items().forEach(item => {
			if (item === this.active_side_nav_main())
				item.sub_pages_visible(false);
		});
		this.active_side_nav_main(null);
	}

	navigate(page_route)
	{
		this.current_page_route(page_route);
		Grape.navigate(`${page_route}`);
	}

	back_to_top()
	{
		document.body.scrollTop = 0; // For Safari
		document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
	}
}

export default {
	name: 'ko-demo-navbar',
	viewModel: NavbarViewModel,
	module_type: 'ko',
	template: template
}
