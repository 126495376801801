import setup from './setup/setup.js';
import setup_page_content from './setup/setup-page-content.js';
import dashboard from './dashboard/dashboard.js';
import custom_fields from './setup/custom-fields/custom-fields.js';
import channels_setup from './setup/channels/channels-setup.js';
import state_settings from './setup/state-settings/state-settings.js';
import service_level_settings from './setup/service-level-settings/service-level-settings.js';
import category_setup from './setup/category-setup/category-setup.js';
import working_hours from './setup/working-hours/working-hours.js';

export default [
	setup,
	setup_page_content,
	dashboard,
	custom_fields,
	channels_setup,
	state_settings,
	service_level_settings,
	category_setup,
	working_hours
];
