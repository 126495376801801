import system_init_page from './system-init/system-init.js';
import components from './components/index.js';

/* Import css */
import './css/CRM.css';

// Import themes
import './css/themes/theme-crm-light.css';
import './css//themes/theme-crm-dark.css';

import pages from './pages/index.js';
import dialogs from './dialogs/index.js';

/* Plugin */
import Plugin from './lib/Plugin.js';

/* Define default routes */
const default_routes = {
	guest: '/grape-ui/log-in',
	all: '/ui/crm/dashboard'
}

Grape.util = Grape.utils;
window.Grape.component.registerComponents(components);
window.Grape.pages.registerPages(pages);
window.Grape.dialog.registerDialogs(dialogs);

/* "main" */
document.addEventListener('DOMContentLoaded', () => {
	/* Setup app */
	Grape.config.debug = true;
	Grape.config.default_routes = default_routes;

	Grape.plugins.register(Plugin);

	/* Register page */
	Grape.route('/ui/crm/system-init', system_init_page);

	Grape.themes.registerThemes([
		{ name: 'SCM', className: 'scm' },
		{ name: 'CRM', className: 'crm' },
		{ name: 'Savanna Classic', className: 'savanna_classic' },
		{ name: 'Yooma Blue', className: 'yooma_blue' },
		{ name: 'Yooma Default', className: 'yooma_default' },
		{ name: 'Yooma Earth', className: 'yooma_earth' },
		{ name: 'Yooma Pink', className: 'yooma_pink' },
		{ name: 'Yooma Purple', className: 'yooma_purple' },
		{ name: 'Catalytix', className: 'catalytix' }
	]);
	Grape.themes.setThemeDefaults('crm', 'light');

	/* Start app */
	Grape.init();
});
