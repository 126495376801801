import template from './add-edit-fields-table.html';
import fields_table_model from './../../lib/models/fields-table-model.js'

class AddEditFieldsTableVM
{
	constructor(dialog)
	{
		this.dialog = dialog;
		this.edit = this.dialog.bindings.edit;
		this.fieldsTable = ko.observable(new fields_table_model(this.dialog.bindings.fieldsTable));
	}

	close()
	{
		this.dialog.close();
	}

	async save()
	{
		let res = await this.fieldsTable().save();
		if (res)
		{
			if (res?.status !== 'OK')
			{
				Grape.alerts.apiError(res);
				return;
			}

			Grape.alerts.alert({type:'success', title:'Success', message:'Successfully Saved.'});
			this.dialog.close(res);
		}
	}
}

class AddEditFieldsTableDialog
{
	constructor(bindings, element)
	{
		this.bindings = bindings || {};
		this.viewModel = new AddEditFieldsTableVM(this);
		this.name = 'add-edit-fields-table';
	}
}

export default {
	name: 'add-edit-fields-table',
	dialog_class: AddEditFieldsTableDialog,
	provider: 'ps',
	template: template
}
