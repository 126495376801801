
class GrapeUIPlugin
{
	constructor(Grape, options)
	{
		this.Grape = Grape;
		this.options = options;
		this.init = false;
	}

	async afterInit()
	{
		this.init = true;
		const el = document.getElementsByClassName('ps-navbar-container')[0];
		try {
			ko.applyBindings({}, el);
		} catch (err) { console.error(err); }

		setTimeout(() => {
			// if publis settings public.system-setup = true then navigate to /ui/crm/system-init
			if (window.Grape.config.public_settings.hasOwnProperty('system_setup'))
				window.Grape.navigate(`/ui/crm/system-init`);
		}, 400);
	}

	beforePageLoad()
	{
		// Show loader
	}

	afterPageLoad()
	{
		// Hide loader
	}
}

export default GrapeUIPlugin;

