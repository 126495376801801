import template from './service-level-settings.html';

class ServiceLevelVM
{
	constructor (page)
	{
		this.page = page;
		this.channel = ko.observableArray(['emails', 'WhatsApp', 'Facebook', 'SMS']);
		this.priority = ko.observableArray(['HIGH', 'MEDIUM', 'LOW', 'URGENT']);
		this.state = ko.observableArray(['OVERDUE', 'NEW', 'IN PROGRESS', 'WAITING FEEDBACK']);
		this.criteria_based = ko.observableArray(['Time: 1st Response', 'Time: 2nd Response', 'Time: 3rd Response', 'Time: 4th Response']);
		this.criteria_value = ko.observableArray(['Greater than (>)', 'Less than (<)', 'Equal to (=)']);
		this.target_time = ko.observableArray(['Day(s)', 'Week(s)', 'Month(s)']);
		this.work_hours_type = ko.observableArray(['Business Hours', 'Weekends', 'Public Holidays']);
	}
}

class ServiceLevelPage
{
	constructor (bindings, element, page)
	{
		this.viewModel = new ServiceLevelVM(this);
		this.bindings = bindings;
		document.title = 'Messages setup: Service Level Settings';
	}

	async init ()
	{
	}

	teardown ()
	{
	}
}

export default {
	route: '[/ui/crm/setup/]service-level-settings',
	page_class: ServiceLevelPage,
	template: template
}
