import template from './custom-fields.html';

/**
 *  FieldsTables view model
 */
class FieldsTablesViewModel
{
	constructor(page)
	{
		this.page = page;
		this.element = page.element;

		this.lookupValueListOptions = ko.observable({filter:[{field:'schema', operator:'=', value:''}]});
		this.selectedFieldsTable = ko.observable();
		this.selectedFieldsTable.subscribe((newValue)=>{
			this.lookupValueListOptions({filter:[
				{field:'schema', operator:'=', value:'contacts'},
				{field:'table', operator:'=', value:newValue.table}
			]});
		});
		this.records = ko.observableArray([]);
		this.selectedLookupValue = ko.observable();
		this.selected = ko.observable();
		this.table_fields = ko.observableArray([
			{
				name: 'display_name'
			},
			{
				name: 'table'
			},
			{
				name: 'idx'
			},
			{
				name: 'options',
				formatter: 'json'
			}
		]);

		this.fields = ko.observableArray();
		this.visible_fields = ko.observableArray([]);
		this.visible_fields.subscribe(() => { this.page.updateData(); });

		this.filters = ko.observableArray([]);
		this.filters.subscribe(() => this.page.updateData());

		this.sortfield = ko.observable();
		this.sortorder = ko.observable('DESC');

		this.page_number = ko.observable();
		this.total_pages = ko.observable();
	}

	async btn_upsert_field_table()
	{
		let res = await Grape.dialog.open('add-edit-fields-table', {fieldsTable:{}});
		if (res?.status === 'OK')
			this.page.updateData();
	}
}

/**
 * FieldsTables class
 */
class FieldsTablesClass
{
	constructor(bindings, element)
	{
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new FieldsTablesViewModel(this);
		this.name = 'FieldsTablesClass';
	}

	async init()
	{
		let options = {};
		
		options = {
			fields: ['display_name', 'table', 'idx', 'options']
		};

		let list = [];
		for (let field of this.viewModel.table_fields())
		{
			if (!field.name)
				continue;
			let newfield = {
				name: field.name,
				visible: ko.observable(options.fields.indexOf(field.name) < 0 ? false : true)
			};

			if (field.formatter)
			{
				if (field.formatter == 'json')
					newfield.formatter = x => JSON.stringify(x);
			}

			list.push(newfield);
		}
		this.viewModel.fields(list);

		if (options.sortfield)
			this.viewModel.sortfield(options.sortfield);
		if (options.sortorder)
			this.viewModel.sortorder(options.sortorder);
		if (options.filter)
			this.viewModel.filters(options.filter);
		
	}

	async updateData()
	{
		this.fetchFieldsTables();
	}

	async fetchFieldsTables()
	{
		let options = this.serializeOptions();
		let result = await Grape.fetches.getJSON('/api/record', options);
		if (result.status == 'OK')
		{
			this.viewModel.records(result.records);
			this.viewModel.page_number(result.page_number);
			this.viewModel.total_pages(result.total_pages);
		}
		else
			Grape.alerts.alert({type: 'error', message: result.message || 'Fail' });
	}

	serializeOptions()
	{
		let fields = ['fields_table_uuid', 'schema', 'table', 'display_name', 'idx', 'options'];
		for (let f of this.viewModel.fields())
			if (f.visible())
				fields.push(f.name);

		let filters = [];
		for (let f of this.viewModel.filters())
			filters.push({field: f.field, operator: f.operator, value: f.value});

		filters.push({field:'schema', operator:'=', value:'contacts'});

		let options = {
			schema: 'fields',
			table: 'fields_table',
			fields: fields,
			filter: filters
		};

		if (this.viewModel.sortfield() && this.viewModel.sortfield().length)
		{
			options.sortfield = this.viewModel.sortfield();
			options.sortorder = this.viewModel.sortorder();
		}

		window.localStorage.setItem('fields.fields-table.fields-table-list-options', JSON.stringify(options));

		return options;
	}

	async editFieldsTable(data, event)
	{
		let res = await Grape.dialog.open('add-edit-fields-table', {fieldsTable:data, edit: true});

		if (res?.status === 'OK')
		{
			this.updateData();
			this.selectFieldsTable('');
		}
	}

	selectFieldsTable(data, event)
	{
		if (data === this.viewModel.selected()) 
			this.viewModel.selected(null);
		else 
			this.viewModel.selected(data);

		this.viewModel.selectedFieldsTable(data);
	}

	async deleteFieldsTable(data)
	{
		let confirm = await Grape.alerts.confirm({type:'warning', title:'Delete', message:`Delete Custom Fields Table <b>${data.display_name}( ${data.schema}.${data.table} )</b>? This may result in loss of data.`, cancel_type:'default', accept_type:'warning'});
		if (confirm)
		{
			let res = await Grape.fetches.deleteJSON('/api/custom-fields/table/delete', {fields_table_uuid:data.fields_table_uuid});
			if (res?.status === 'OK')
			{
				this.updateData();
				this.viewModel.selectedFieldsTable('');
				return res;
			}

			Grape.alerts.apiError(res);
		}
	}

	async teardown()
	{}
};

export default {
	route: '[/]ui/crm-setup/custom-fields',
	page_class: FieldsTablesClass,
	template: template,
	title: 'Custom Fields',
	icon: 'fa-regular fa-list',
	idx: 10
}
