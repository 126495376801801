import template from './table-fields-view.html';

/**
 * This is an example knockout component class. Note the export section at the end of the file.
 * @kind component
 * @class SampleComponent
 * @description Sample Component that shows the name value
 */
class FieldListViewModel
{
	constructor(params)
	{
		this.params = params;

		this.data = ko_helper.safe_observable(params.data);
		this.fieldsTable = ko_helper.safe_observable(params.fieldsTable);
		this.selectedField = ko_helper.safe_observable(params.selectedField);
		this.fieldListOptions = ko_helper.safe_observable(params.fieldListOptions || {});
		this.fieldListOptions.subscribe((newValue)=>{
			this.init();
		});

		this.records = ko.observableArray([]);

		this.table_fields = ko.observableArray([
			{
				name: 'display_name'
			},
			{
				name: 'name'
			},
			{
				name: 'description'
			},
			{
				name: 'idx'
			},
			{
				name: 'options',
				formatter: 'json'
			}
		]);

		this.fields = ko.observableArray();
		this.visible_fields = ko.observableArray([]);
		this.visible_fields.subscribe(() => { this.update(); });

		this.filters = ko.observableArray([]);
		this.filters.subscribe(() => this.update());

		this.sortfield = ko.observable();
		this.sortorder = ko.observable('DESC');

		this.page_number = ko.observable();
		this.total_pages = ko.observable();

		this.init();
	}

	async init(){
		let options = {};
		if (window.localStorage.getItem('fields.table-fields.field-list-options'))
			options = JSON.parse(window.localStorage.getItem('fields.table-fields.field-list-options'));
		else
			options = {fields: ['display_name', 'name', 'description', 'idx', 'options']};

		options = Object.assign(options, this.fieldListOptions());

		const list = [];
		for (let field of this.table_fields())
		{
			if (!field.name)
				continue;
			const newfield = {
				name: field.name,
				visible: ko.observable(options.fields.indexOf(field.name) < 0 ? false : true)
			};

			if (field.formatter){
				if (field.formatter == 'json')
					newfield.formatter = x => JSON.stringify(x);
			}

			list.push(newfield);
		}
		this.fields(list);

		if (options.sortfield)
			this.sortfield(options.sortfield);
		if (options.sortorder)
			this.sortorder(options.sortorder);
		if (options.filter)
			this.filters(options.filter);
	}

	async update() {
		this.fetchFields();
	}

	async fetchFields(){
		const options = this.serializeOptions();
		const result = await Grape.fetches.getJSON('/api/record', options);

		if (result.status == 'OK')
		{
			this.records(result.records);
			this.page_number(result.page_number);
			this.total_pages(result.total_pages);
		}
		else
			Grape.alerts.alert({type: 'error', message: result.message || 'Fail' });
	}

	serializeOptions()
	{
		const fields = ['field_uuid', 'fields_table_uuid', 'display_name', 'name', 'description', 'idx', 'options', 'table'];

		for (let f of this.fields())
			if (f.visible())
				fields.push(f.name);

		const filters = [];

		for (let f of Object.assign(this.filters(), this.fieldListOptions().filter||[]))
			filters.push({field: f.field, operator: f.operator, value: f.value});

		filters.push({ field: 'schema', operand: '=', value: 'contacts' });

		const options = {
			schema: 'fields',
			table: 'v_fields',
			fields: fields,
			filter: filters
		};

		if (this.sortfield() && this.sortfield().length)
		{
			options.sortfield = this.sortfield();
			options.sortorder = this.sortorder();
		}

		window.localStorage.setItem('fields.table-fields.field-list-options', JSON.stringify(options));

		return options;
	}

	async editField(data, event){
		let res = await Grape.dialog.open('add-edit-table-field', {tableField:data, edit: true});

		if (res?.status === 'OK'){
			this.update();
			this.selectedField('');
		}

	}

	async upsertTableField(){
		let schema = this.fieldsTable()?.schema || '';
		let table = this.fieldsTable()?.table || '';
		let fields_table_uuid = this.fieldsTable()?.fields_table_uuid || '';

		if (schema && table){
			let res = await Grape.dialog.open('add-edit-table-field', {
				tableField:{
					schema,
					table,
					fields_table_uuid
				}
			});
			if (res?.status === 'OK'){
				this.update();
			}
		}
	}

	async deleteField(data){
		let confirm = await Grape.alerts.confirm({type:'warning', title:'Delete', message:`Delete Custom Field <b>${data.display_name}( ${data.name} )</b>? This may result in loss of data.`, cancel_type:'default', accept_type:'warning'});

		if (confirm){
			let res = await Grape.fetches.deleteJSON('/api/custom-fields/field/delete', {field_uuid:data.field_uuid});
			if (res?.status === 'OK'){
				this.update();
				return res;
			}

			Grape.alerts.apiError(res);
		}
	}
}

export default {
	name: 'table-fields-view',
	viewModel: FieldListViewModel,
	module_type: 'ko',
	template: template
};