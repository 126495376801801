import template from './channels-setup.html';

class ChannelsVM
{
	constructor (page)
	{
		this.page = page;
		this.channels = ko.observableArray([]);
	}

	async load()
	{
		const result = await Grape.fetches.getJSON(`/api/record`, {schema: 'messages', table: 'v_channels'});
		this.channels(result.records || []);
	}

	async btnChannel(channel=null)
	{
		const result = await Grape.dialog.open('EditChannelDialog', channel ? {channel: channel.name} : {});
		console.log('result=',result);
	}
}

class ChannelsPage
{
	constructor (bindings, element, page)
	{
		this.viewModel = new ChannelsVM(this);
		this.bindings = bindings;
		document.title = 'Messages setup: Channels';
	}

	async init ()
	{
		this.viewModel.load();
	}

	teardown ()
	{
	}
}

export default {
	route: '[/ui/crm/setup/]channels',
	page_class: ChannelsPage,
	template: template
}
