import template from './checkbox-dropdown.html';

class CheckboxDropdownComponentViewModel
{
	constructor(params)
	{
		this.params = params;
		this.selected_items = ko_helper.safe_observableArray(params.selected_items());
		this.title = ko.observable(params.title || 'Select items...');
		this.show_label_count = ko.observable(2);
		this.items_objects = ko.observableArray([]);
		this.available_items = ko.observable();

		this.available_items.subscribe((newValue) => {
			this.build_selected_items_obj(newValue);
		});

		this.selected_items.subscribe((newSelectedItems) => {
			for (let item_object of this.items_objects()) {
				item_object.checked(newSelectedItems.includes(item_object.name));
			}
		});

		this.selected_objects_name_str = () => { 
			let names = this.selected_items(); 
			return names.join(', ');   
		};

		this.init();
	}

	init ()
	{
		if (this.params.available_items())
			this.available_items(this.params.available_items());
	}

	build_selected_items_obj (items)
	{
		let obj_array = [];
		let available_items = [];
		
		if (this.available_items() == undefined)
			available_items = items;
		else 
			available_items = this.available_items();

		for (let value of available_items)
		{
			obj_array.push({
				...value,
				checked: ko.observable( (this.selected_items().includes(value) ? true : false) )
			});
		}

		this.items_objects(obj_array);
		console.log('items object after build funtion: ', this.items_objects());
	}

	checked_all_click ()
	{
		let check_status = this.selected_items().length !== this.items_objects().length;

		for (let value of this.items_objects())
			value.checked(check_status);

		this.update_selected_items();
		return true;
	}

	checked_click ()
	{
		this.update_selected_items();
		return true;
	}

	update_selected_items ()
	{
		let temp_arr = [];

		for (let value of this.items_objects())
		{
			if (value.checked())
				temp_arr.push(value.name);
		}

		this.selected_items(temp_arr);
	}
}

export default {
	name: 'checkbox-dropdown',
	viewModel: CheckboxDropdownComponentViewModel,
	module_type: 'ko',
	template: template
}
