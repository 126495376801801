class fields_table_model
{
	constructor({fields_table_uuid, table, display_name, idx, options})
	{
		this.fields_table_uuid = fields_table_uuid;
		this.schema = 'contacts';
		this.table = table;
		this.display_name = display_name;
		this.idx = idx;
		this.options = options;

		if (typeof this.options === 'object')
			this.options = JSON.stringify(this.options);
	}

	fetchFieldsTable()
	{
		// TODO
	}

	async save()
	{
		if (!this.schema)
			await Grape.alerts.alert({type: 'warning', message: 'Schema name cannot be empty!'});
		else if (!this.table)
			await Grape.alerts.alert({type: 'warning', message: 'Table name cannot be empty!'});

		else
		{
			let payload = this.serialize();
			if (typeof payload.options === 'string')
			{
				try {
					payload.options = JSON.parse(payload.options);
				} catch (err) {
					return {status: 'ERROR', code: 'Invalid Data', message:'Options is not valid JSON'};
				}
			}

			return Grape.fetches.postJSON('/api/custom-fields/table/upsert', payload);
		}
	}

	async delete()
	{
		return Grape.fetches.deleteJSON('/api/custom-fields/table/delete', {fields_table_uuid: this.fields_table_uuid});
	}

	serialize()
	{
		return {
			fields_table_uuid: this.fields_table_uuid,
			schema: this.schema,
			table: this.table,
			display_name: this.display_name,
			idx: this.idx,
			options: this.options
		};
	}
}

export default fields_table_model;
